<template>
  <div class>
    <!-- Pop up modal -->
    <mdb-modal
      frame
      style="background-color: transparent; pointer-events:none; touch-action:none;"
      position="bottom"
      direction="top"
      :show="modal"
      @close="modal = false"
    >
      <mdb-modal-body :class="['text-center',modalClass]">
        <i :class="modalIcon"></i>&nbsp;&nbsp;&nbsp;&nbsp;
        <span>{{modalMessage}}</span>
      </mdb-modal-body>
    </mdb-modal>

    <div class="exhibition-content w-100">
      <div class="row p-0 m-0 h-100">
        <!-- Start Chats section -->

        <div class="col-md-4 h-100 mx-0 px-0">
          <div class="button_container">
            <div
              :class="['btn_1 px-2','flex-fill','status-button',statusButtonClass(0)]"
              @click="updateSelectedStatus(0)"
            >Besucher</div>
            <div
              :class="['btn_2 px-2','flex-fill','status-button',statusButtonClass(1)]"
              @click="updateSelectedStatus(1)"
            >In meinem Bereich</div>
            <div
              @click="setBusy()"
              :class="['px-2','flex-fill', 'sleep', {'isBusy':checkIfBusy(userData.uuid)}]"
            >
              <img
                @click="toggleBusy"
                class="crescent_moon"
                src="~/assets/images/crescent_moon.svg"
              />
            </div>
            <div
              :class="['btn_3 px-2 hasMessageNumber','flex-fill','status-button',statusButtonClass(2)]"
              @click="updateSelectedStatus(2)"
            >
              <div>{{statusArray[2]}}</div>
              <span v-if="$store.state.lastDistributorCallLength >0">1</span>
            </div>
            <div
              :class="['btn_4 px-2 hasMessageNumber','flex-fill','status-button',statusButtonClass(3)]"
              @click="updateSelectedStatus(3)"
            >
              <div>Begonnene Chats</div>
              <span v-if="unreplayed.length>0">{{unreplayed.length}}</span>
            </div>
          </div>

          <div class="people-container">
            <div class="d-flex flex-row text-center chat-container">
              <input
                class="form-control form-control-sm text-field"
                type="text"
                :placeholder="'Suchen nach Name, Firma oder Raum'"
                aria-label="Search"
                v-model="filterTargetArray"
              />
              <span class="search-icon">
                <img src="../assets/Icons/Icons/Icon-Search-black.svg" class="search-icon-img" />
              </span>
            </div>
            <div
              v-for="(user, index) in filteredTargetArray"
              :key="user.uuid"
              :class="['people',{'busy':checkIfBusy(user.uuid)}, index == targetArray.length - 1 ? 'people-no-margin' : '', selectedPerson.uuid == user.uuid ? 'selected-person' : 'not-selected-person',isUnreplayed(user)? 'unreplayed':'']"
              @click="updateselectedPerson(user)"
            >
              <div class="person-info white-text ml-2 mt-2">
                <p class="p-0 m-0">
                  {{user.first_name}} {{user.last_name}}
                  <span
                    v-if="user.current_booth_title || user.current_exhibit_booth_title"
                  >({{user.current_booth_title ? user.current_booth_title : user.current_exhibit_booth_title}})</span>
                </p>
                <p v-if="user.company" class="p-0 m-0">{{user.company}}</p>
              </div>
              <div
                :class="['row','row-no-padding','align-items-center','h-100','message-icon-container',selectedPerson.uuid == user.uuid ? 'selected-message-icon-container' : 'not-selected-message-icon-container']"
              >
                <img src="../assets/Icons/Icons/Icon-Chat.svg" class="message-icon" />
              </div>
            </div>
          </div>
        </div>

        <!-- End Chats section  -->

        <div v-if="selectedPerson.uuid" class="col-md-8 h-100 mx-0 px-0 right-section">
          <div class="seperator mobile-seperator mt-4 mb-4"></div>
          <div class="row p-0 m-0 upper-section w-100">
            <!-- Start Contact section -->
            <div class="col-md-6 mx-0 px-0 contact-section d-flex flex-column">
              <div class="title-container w-100">
                <p class="p-0 m-0 white-text store-sub-title w-100">{{contact.company}}</p>
                <h1 class="p-0 m-0 store-title w-100">{{contact.first_name}} {{contact.last_name}}</h1>
              </div>
              <div class="p-0 mx-0 w-100 row contact-info">
                <div class="col-md-6 p-0 m-0 white-text">
                  <p v-if="contact.company_position" class="mb-0 info">{{contact.company_position}}</p>
                </div>
                <div class="col-md-6 p-0 m-0 white-text">
                </div>
              </div>

              <div v-if="contact.first_name" class="p-0 mx-0 w-100 mt-auto cover">
                <div class="row p-0 mx-0">
                  <div class="col-md-8 p-0">
                    <div>
                      <!-- <textarea
                        rows="1"
                        v-model="note"
                        class="text-center pt-4 m-0 cover-content w-100"
                        :placeholder="$t('views.contact_note_placeholder')"
                      ></textarea>-->
                    </div>
                  </div>
                  <div class="col-md-4 p-0 m-0">
                    <div
                      class="d-flex flex-md-column flex-sm-row align-items-start justify-content-center note-buttons-container h-100"
                    >
                      <!-- <button type="button" class="btn mx-0 p-0 mb-md-auto mt-md-0 note-button share-button">
                      <div class="button-content">
                        <img src="../assets/Icons/Icons/Icon-Share.svg" class='mr-2 button-icon'>
                        <span class="button-text">{{$t('views.share')}}</span>
                      </div>
                      </button>-->
                      <!-- <button
                        type="button"
                        class="btn p-0 mx-0 mt-md-auto mb-md-0 note-button save-button"
                      >
                        <div class="button-content">
                          <img src="../assets/Icons/Icons/Icon-Save.svg" class="mr-2 button-icon" />
                          <span class="button-text" @click="saveNote()">{{$t('views.save')}}</span>
                        </div>
                      </button>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- End Contact section -->
            <div class="seperator mobile-seperator mt-4 mb-4"></div>

            <!-- start search section -->
            <div class="col-md-6 mx-0 px-0 exhibits-section d-flex flex-column">
              <div class="search-section px-0">
                <p class="row white-text p-0 m-0 search-title">{{$t('views.search_our_products')}}</p>
                <div class="d-flex flex-row text-center search-container">
                  <input
                    class="form-control form-control-sm text-field"
                    type="text"
                    :placeholder="$t('views.search')"
                    aria-label="Search"
                    v-model="filter"
                  />
                  <span class="search-icon">
                    <img src="../assets/Icons/Icons/Icon-Search-black.svg" class="search-icon-img" />
                  </span>
                </div>
              </div>
              <div class="product-container">
                <div
                  v-for="(exhibit,index) in filteredExhibits"
                  :key="index"
                  :class="['product', index == 0 ? 'first-product' : '']"
                >
                  <div class="d-flex flex-row clickable" @click="ShowProduct(exhibit)">
                    <div class="product-info white-text ml-2 mt-2">
                      <p class="p-0 m-0" v-html="exhibit.note_title"></p>
                    </div>
                    <div
                      :class="['row','row-no-padding','align-items-center','exhibit-icon-container']"
                    >
                      <img src="../assets/Icons/Icons/Icon-Exhibit.svg" class="exhibit-icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end search section -->
          </div>

          <div class="seperator"></div>

          <!-- start chat section -->
          <div v-if="selectedPerson.uuid" class="row p-0 m-0 lower-section w-100">
            <div class="messages w-100">
              <div ref="messages-container" class="messages-container">
                <div v-for="(message,index) in chatHistory" :key="index" class="w-100 message">
                  <div
                    :class="['d-flex', message.sender === user.uuid ? 'justify-content-end' : 'justify-content-start']"
                  >
                    <div class="d-inline-flex flex-column message-box">
                      <div
                        :class="['p-2',message.sender === user.uuid ? 'sender-class' : 'reciever-class']"
                      >
                        <p v-html="message.chatMessage" v-linkified></p>
                      </div>
                      <div
                        :class="['time',message.sender === user.uuid ? 'text-right' : 'text-left']"
                      >
                        {{message.createdAt | moment('hh:mm')}}
                        <span
                          v-if="message.sender === selectedPerson.uuid"
                        >, {{selectedPerson.first_name}} {{selectedPerson.last_name}}</span>
                        <span v-else>, {{$t('views.me')}}</span>
                      </div>
                      <!-- <div
                        :class="['time',message.sender === user.uuid ? 'text-right' : 'text-left']"
                      >
                        {{messageDate(message.createdAt)}}
                        <span
                          v-if="message.sender === selectedPerson.uuid"
                        >, {{selectedPerson.first_name}} {{selectedPerson.last_name}}</span>
                        <span v-else>, {{$t('views.me')}}</span>
                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex input-container">
              <!-- <EmojiContainer class='emoji-container' :append="append" /> -->
              <textarea
                class="flex-grow-1 md-textarea input-box pl-2"
                v-on:keyup.enter="send"
                v-model="message"
              ></textarea>
              <button type="button" @click="send" class="btn massenger-button send-button">
                <div class="button-content">
                  <img src="../assets/Icons/Icons/Icon-Send.svg" class="mr-2 send-icon" />
                  <span class="chat-button-text">{{$t('views.send')}}</span>
                </div>
              </button>
              <button type="button" class="btn massenger-button" @click="goToVideo()">
                <div class="button-content">
                  <img src="../assets/Icons/Icons/Icon-Video.svg" class="mr-2 video-icon" />
                  <span class="chat-button-text">{{$t('views.video')}}</span>
                </div>
              </button>
            </div>
          </div>
          <!-- end chat section -->
        </div>
        <div class="no_chat" v-else>
          <div>
            <img src="@/assets/images/Icon-no-chat.svg" alt />
            <p>Wählen Sie eine Person aus der Liste links aus, um einen Live-Chat zu starten.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import EmojiContainer from 'components/shared/EmojiContainer.vue';
import { mdbModal, mdbModalBody } from 'mdbvue';
/* eslint-disable */
import moment from 'moment';
export default {
  name: 'Ausstelleransicht',
  props: {
    user: Object,
    onlineList: Array,
    errorMessage: Object,
    chatMessage: Object,
    messageNumbers: Number,
    unreplayed: Array,
    removeFromUnreplayed: Function,
    setSelectedExhibit: Function,
    toggleShowProduct: Function
  },
  components: {
    // EmojiContainer,
    mdbModal,
    mdbModalBody
  },
  data() {
    return {
      selectedPerson: {},
      selectedStatus: 0,
      statusArray: [
        this.$t('views.contacts_at_booth'),
        this.$t('views.active_contacts'),
        this.$t('views.sales_inquiry'),
        this.$t('views.my_area')
      ],
      users: [],
      refreshDisributorCall:null,
      OnlineUsers: [],
      map: [],
      contact: {},
      note: '',
      modal: false,
      modalMessage: '',
      modalIcon: '',
      modalClass: '',
      filter: '',
      filterTargetArray: '',
      targetArray: [],
      tempDetails: [],
      finalDetails: [],
      message: '',
      activ: [],
      onlineActiv: [],
      messages: [],
      prevMessage: '',
      tempHistory: [],
      chatHistory: [],
      onlineActiveIds: [],
      localUser: {},
      today: new Date()
    };
  },
  async beforeMount() {
    this.$store.commit('getActiveUsers');
    if (this.unreplayed.length > 0) {
      this.updateSelectedStatus(3);
    } else {
      this.updateSelectedStatus(0);
    }
  },
  beforeDestroy(){
    clearInterval(this.refreshDisributorCall);
  },
  methods: {
    ShowProduct(exhibit) {
      this.setSelectedExhibit(exhibit);
      this.toggleShowProduct();
    },
    async toggleBusy() {
      try {
        this.$http.post('online-status', {
          params: { user: this.$store.state.user.uuid, status: 'busy' }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async updateselectedPerson(user) {
      this.message = '';
      this.$router.replace('/exhibitor/' + user.uuid);
      this.removeFromUnreplayed(user.uuid);
      this.selectedPerson = user;
      this.getChatHistory();
    },
    getChatHistory() {
      this.$http
        .get(
          `/chat-history-sender?recipient=${this.selectedPerson.uuid}`
        )
        .then(resp => {
          this.tempHistory = resp.data;
          this.getRecievedHistory();
        })
        .catch(err => {
          console.log(err);
        });
    },
    getRecievedHistory() {
      this.$http
        .get(
          `/chat-history-recipient?sender=${this.selectedPerson.uuid}`
        )
        .then(resp => {
          this.tempHistory = this.tempHistory.concat(resp.data);
          this.sortHistory();
        })
        .catch(err => {
          console.log(err);
        });
    },
    checkIfBusy(uuid) {
      return this.$store.state.isBusy.includes(uuid) ? true : false;
    },
    sortHistory() {
      this.tempHistory.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      this.chatHistory = this.tempHistory;
      this.$nextTick(() => {
        this.$refs['messages-container'].scrollTop = this.$refs[
          'messages-container'
        ].scrollHeight;
      });
    },
    async pullDistributors(){
      let distributerCall = await this.$http.get('distributor-call');
        let users = distributerCall.data;
        let userIDs = users.map(user => user.customer);
        let filterDistributorUsers = this.usersOnline.filter(user =>
          userIDs.includes(user.uuid)
        );
      this.targetArray = filterDistributorUsers;
    },
    async updateSelectedStatus(index) {
      clearInterval(this.refreshDisributorCall);
      this.selectedStatus = index;
      if (this.selectedStatus == 1) {
        let booth_users = await this.$http.get('/booth-users');
        let isOnlineList = this.$store.state.usersOnline.map(user => user.uuid);
        let filterBoothUsers = booth_users.data.visitors
          .filter(user => user.current_booth === this.userData.supervised_booth)
          .filter(user => isOnlineList.includes(user.uuid));
        this.targetArray = filterBoothUsers;
      }
      // Filter Users for Vertriebsanfragen
      if (this.selectedStatus == 2) {
        this.pullDistributors();
        this.refreshDisributorCall  = setInterval(async ()=>{
          this.pullDistributors();
        },5000)
      }else {
        clearInterval(this.refreshDisributorCall);
      }
      // Filter Active Contacts
      if (this.selectedStatus == 3) {
        let resp = await this.$http.get(
          '/chat-recipients?sender=' + this.$store.state.user.uuid
        );
        let activeUsers = [...this.unreplayed, ...resp.data];
        let users = this.$store.state.allUsers.filter(user =>
          activeUsers.includes(user.uuid)
        );
        users.map(user => {
          this.isUnreplayed(user)
            ? (user.has_new_message = true)
            : (user.has_new_message = false);
          return user;
        });
        users = users
          .sort((a, b) => a.has_new_message - b.has_new_message)
          .reverse();
        this.targetArray = users;
      }

    },
    async setBusy() {
      if (!this.checkIfBusy(this.userData.uuid)) {
        this.$store.state.isBusy.push(this.userData.uuid);
        await this.$http.post(
          '/online-status',
          JSON.stringify({
            user: this.userData.uuid,
            status: 'busy'
          })
        );
      } else {
        this.$store.state.isBusy = this.$store.state.isBusy.filter(userID => userID !== this.userData.uuid);
        await this.$http.post(
          '/online-status',
          JSON.stringify({
            user: this.userData.uuid,
            status: 'online'
          })
        );
      }
    },
    statusButtonClass(status) {
      return this.selectedStatus == status
        ? 'selected-status'
        : 'not-selected-status';
    },
    getContact(selectedPerson) {
      if (selectedPerson) {
        this.$http
          .get('/contact', {
            params: {
              user_uuid: selectedPerson
            }
          })
          .then(resp => {
            this.contact = resp.data;
            this.getNote();
          })
          .catch(err => {
            this.contact = {};
            console.log(err);
          });
      }
    },
    getNote() {
      this.$http
        .get('/contact-note', {
          params: {
            uuid: this.contact.uuid
          }
        })
        .then(resp => {
          resp.data.text ? (this.note = resp.data.text) : (this.note = '');
        })
        .catch(err => {
          console.log(err);
        });
    },
    saveNote() {
      this.$http
        .post('/contact-note', {
          contact_uuid: this.contact.uuid,
          text: this.note
        })
        .then(() => {
          this.modal = true;
          this.modalMessage = this.$t('views.note_saved');
          this.modalIcon = 'fas fa-check-circle';
          this.modalClass = 'green-text';
          setTimeout(
            function() {
              this.modal = false;
            }.bind(this),
            3000
          );
        })
        .catch(() => {
          this.modal = true;
          this.modalMessage = this.$t('views.note_error');
          this.modalIcon = 'fas fa-times-circle';
          this.modalClass = 'red-text';
          setTimeout(
            function() {
              this.modal = false;
            }.bind(this),
            3000
          );
        });
    },
    getFavorites(uuid) {
      this.$http
        .get('/favorites', {
          params: {
            user_uuid: uuid
          }
        })
        .then(resp => {
          console.log(resp);
          if (!resp.data.message) {
            this.getFavoritesDetails(resp.data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getFavoritesDetails(favorites) {
      var i;
      for (i = 0; i < favorites.length; i++) {
        this.getExhibitDetails(favorites[i].exhibit_uuid);
      }
      this.finalDetails = this.tempDetails;
    },
    async getExhibitDetails(uuid) {
      this.$http
        .get('/exhibit', {
          params: {
            uuid: uuid
          }
        })
        .then(resp => {
          this.tempDetails.push(resp.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    append(emoji) {
      this.message += emoji;
    },
    async send() {
      if (this.message.length > 0) {
        var today = new Date();

        window.websocket.send(
          JSON.stringify({
            wsMessageType: 'e2e',
            sender: this.user.uuid,
            recipient: this.selectedPerson.uuid,
            message: this.message
          })
        );
        this.prevMessage = this.message;
        this.chatHistory.push({
          chatMessage: this.message,
          sender: this.user.uuid,
          recipient: this.selectedPerson.uuid,
          createdAt: today
        });
        if (this.selectedStatus === 2) {
          let updateUser = await this.$http.put(
            'distributor-call',
            JSON.stringify({
              customer: this.selectedPerson.uuid,
              active: false,
              distributer: this.$store.state.user.uuid
            })
          );
        }
        this.$nextTick(() => {
          this.$refs['messages-container'].scrollTop = this.$refs[
            'messages-container'
          ].scrollHeight;
          this.onlineActiveIds.includes(this.selectedPerson.uuid) ? '' : null;
        });
        if (this.selectedStatus !== 3) {
          this.updateselectedPerson(this.selectedPerson);
          this.updateSelectedStatus(3);
        }
        this.message = '';
      }
    },
    async goToVideo() {
      var videoinfotext =
        'Ich habe gerade einen Video-Chat für Sie gestartet. Bitte drücken Sie auf den Knopf "Video an" um beizutreten.';
      this.message = videoinfotext;
      this.send();
      await this.$store.commit('getActiveUsers');
      if (this.selectedPerson.uuid) {
        localStorage.removeItem('video-domain');
        localStorage.removeItem('room-name');
        let baseURL = window.location.host;
        window.open(
          `http://${baseURL}/videochat/` + this.selectedPerson.uuid,
          '_blank'
        );
        // this.$router.push({ path: '/videochat/' + this.selectedPerson.uuid });
      }
    },
    isToday(date) {
      return (
        date.getDate() == this.today.getDate() &&
        date.getMonth() == this.today.getMonth() &&
        date.getFullYear() == this.today.getFullYear()
      );
    },
    padLeft(num) {
      if (num < 10) {
        return '0' + num;
      } else {
        return num;
      }
    },
    isUnreplayed(user) {
      if (
        this.selectedPerson.uuid != user.uuid &&
        this.unreplayed.includes(user.uuid)
      ) {
        return 'unreplayed';
      }
    },
    filterUsers(users) {
      return users.filter(user => {
        let name = user.first_name + ' ' + user.last_name;
        name = name.toLowerCase();
        let booth = user.current_booth_title
          ? user.current_booth_title.toLowerCase()
          : null;
        let exhibit = user.current_exhibit_booth_title ? user.current_exhibit_booth_title.toLowerCase(): null;
        let filter = this.filterTargetArray.toLowerCase();
        return (
          name.includes(filter) ||
          (booth ? booth.includes(filter) : false) ||
          (exhibit ? exhibit.includes(filter) : false) ||
          (user.company ? user.company.toLowerCase().includes(filter) : false)
        );
      });
    }
  },
  watch: {
    usersOnline() {
      this.updateSelectedStatus(this.selectedStatus);
    },
    selectedPerson() {
      this.note = '';
      this.finalDetails = [];
      this.tempDetails = [];
      this.getContact(this.selectedPerson.uuid);
      this.getFavorites(this.selectedPerson.uuid);
    },
    onlineList() {
      this.getActiv();
    },
    errorMessage() {},
    chatMessage() {
      this.updateSelectedStatus(3);
      if (
        this.chatMessage.recipient == this.selectedPerson.uuid ||
        this.chatMessage.sender == this.selectedPerson.uuid
      ) {
        var time = new Date();
        this.chatHistory.push({
          chatMessage: this.chatMessage.message,
          sender: this.chatMessage.sender,
          recipient: this.chatMessage.recipient,
          createdAt: time
        });
        this.$nextTick(() => {
          this.$refs['messages-container'].scrollTop = this.$refs[
            'messages-container'
          ].scrollHeight;
        });
      }
    },
    async getUsersAsync(onlineList) {
      let response = await this.$http.get('/users');
      let users = response.data;
      return onlineList;
    }
  },
  computed: {
    userData() {
      return this.$store.state.user;
    },
    filteredTargetArray() {
      let arrayToFilter =
        this.selectedStatus === 0 ? this.usersOnline : this.targetArray;
      return this.filterTargetArray !== ''
        ? this.filterUsers(arrayToFilter)
        : arrayToFilter;
    },
    usersOnline() {
      return this.$store.state.usersOnline;
    },
    filteredExhibits() {
      return this.filter
        ? this.finalDetails.filter(exhibit =>
            exhibit.title.toLowerCase().includes(this.filter.toLowerCase())
          )
        : this.finalDetails;
    }
  }
};
</script>

<style lang="scss" scoped>
.isBusy {
  background: $primaryColor !important;
}
.clickable {
  cursor: pointer;
}
.chat-text {
  width: 100%;
}
.no_chat {
  img {
    width: 87.84;
  }
  div {
    display: flex;
    flex-direction: column;
  }
  p {
    max-width: 324px;
    text-align: center;
    margin-top: 15px;
  }
  color: #9d9d9d;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.crescent_moon {
  height: 32.27px;
}
.button_container {
  margin-bottom: 30px;
  display: grid;
  grid-template-areas:
    "btn_1 btn_2 sleep"
    "btn_3 btn_4 sleep";
  row-gap: 1px;
  column-gap: 1px;
  grid-template-columns: 1fr 1fr 69px;
  grid-template-rows: auto auto;
}
.sleep {
  background: #696969;
  cursor: pointer;
  grid-area: sleep;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_1 {
  grid-area: btn_1;
}
.btn_2 {
  grid-area: btn_2;
}
.btn_3 {
  grid-area: btn_3;
}
.btn_4 {
  grid-area: btn_4;
}

.hasMessageNumber {
  display: flex;
  justify-content: space-between;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primaryColor;
    color: white;
    border-radius: 50%;
    height: 20.69px;
    width: 20.69px;
  }
}
textarea {
  vertical-align: top;
}

button {
  border-radius: 0;
  background-color: white;
}

button:hover {
  background-color: $secondaryColor;
}

.row-no-padding {
  margin-left: 0;
  margin-right: 0;
}

.exhibition-content {
  background-color: #000000e6;
  max-width: 100%;
  height: calc(100vh - 120px);
  padding-top: 51px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
}

/* Start chats css */

.status-buttons-container {
  padding-right: 40px;
  margin-bottom: 61.25px;
}

.not-selected-status {
  color: #c0c0c0;
  background-color: #696969;
}

.selected-status {
  color: white;
  background-color: $primaryColor;
  color: white;
}

.status-button {
  font-size: 16px;
  height: 34px;
  font-weight: 400;
  padding-top: 5px;
}

.status-button:hover {
  cursor: pointer;
}

.people-container {
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
  padding-right: 40px;
  height: calc(100% - (61.25px + 34px));
}

.person-info {
  font-size: 16px;
  display: inline-block;
  white-space: nowrap;
  width: 84%;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: 400;
}

.people {
  margin-top: 21px !important;
  height: 65px;
  border: 1px solid;
  width: 100%;
  position: relative;
}

.no-top-margin {
  margin-top: 0px !important;
}

.no-bottom-margin {
  margin-bottom: 0px !important;
}

.not-selected-person {
  border-color: white;
}

.message-icon-container {
  width: 45px;
  position: absolute;
  right: 0;
  top: 0;
}

.not-selected-message-icon-container {
  background-color: white;
}

.people:hover {
  cursor: pointer;
  border-color: $secondaryColor;
}

.people:hover .message-icon-container {
  background-color: $secondaryColor;
}

.selected-message-icon-container {
  background-color: $secondaryColor !important;
}

.selected-person {
  border-color: $secondaryColor !important;
}

.message-icon {
  width: 25px;
  height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.note-buttons-container {
  margin-left: 19px;
}

.unreplayed {
  position: relative;
  &::after {
    content: "";
    height: 12px;
    width: 12px;
    background: $primaryColor;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 5px;
  }
}
/* End chats css */

/* Start Contact css */

.contact-section {
  height: 100%;
  overflow-y: auto;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
  position: relative;
  padding-right: 20px !important;
  border-right: 1px solid #d8bfd880;
}

.store-sub-title {
  letter-spacing: 0.49px;
  font-size: 19px;
  font-weight: 400 !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
  line-height: 19px;
}

.store-title {
  font-weight: 300 !important;
  font-size: 33px;
  color: $secondaryColor;
  letter-spacing: 0.56px;
  text-transform: uppercase;
  margin-top: 16px !important;
}

.contact-info {
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  line-height: 12px;
  padding-right: 20px;
  margin-bottom: 20px;
}

.cover-content {
  background-color: rgba(128, 128, 128, 0.7);
  color: white;
  font-weight: 400;
  font-size: 15px;
  border: 0;
  resize: none;
  height: 92px;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
}

.cover-content::placeholder {
  color: white;
}

.note-button {
  font-size: 14px !important;
  position: relative;
  width: 100%;
  height: 37px;
  background-color: $secondaryColor !important;
  text-transform: none;
}

.button-icon {
  width: 22px;
  height: 22px;
}

/* End Contact css */

/* Start Search Css  */
.exhibits-section {
  padding-left: 20px !important;
  height: 100%;
}

.search-section {
  margin-bottom: 20px;
}

.search-title {
  letter-spacing: 0.49px;
  font-size: 19px;
  font-weight: 400 !important;
  line-height: 18px;
  padding-right: 40px;
}

.chat-container {
  margin-bottom: 30px;
  .text-field {
    height: 34px;
    border-radius: 0%;
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: 0 !important;
    color: white;
  }
}

.search-container {
  margin-top: 15px;
  border-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  padding-right: 40px;
}

.search-container .text-field {
  height: 34px;
  border-radius: 0%;
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 0 !important;
  color: white;
}

.search-icon {
  background-color: rgba(255, 255, 255, 0.7);
  width: 34px;
  position: relative;
}

.search-icon-img {
  width: 17.49px;
  height: 17.49px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.product-container {
  overflow-y: auto;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
  position: relative;
  padding-right: 40px;
}

.product-info {
  font-size: 16px;
  display: inline-block;
  white-space: nowrap;
  width: 95% !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.product {
  margin-top: 18px !important;
  height: 42px;
  border: 1px solid white;
  width: 100%;
}

.first-product {
  margin-top: 0px !important;
}

.exhibit-icon-container {
  width: 41px;
  height: 41px !important;
  background-color: white;
  position: relative;
}

.exhibit-icon {
  width: 28.27px;
  height: 25.57px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
/* End Search Css */

/* Start Chat Css */

.messages {
  height: calc(100% - 37px);
  padding-bottom: 15px;
}

.messages-container {
  overflow-y: auto;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
  height: 100%;
  width: 100% !important;
  padding-right: 40px;
}

.message {
  padding-bottom: 10px;
}

.time {
  font-size: 12px;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.5);
  font-style: italic;
  margin-top: 5px;
}

.time.text-left {
  margin-left: 2.6%;
}

.sender-class {
  background-color: #888888;
  float: right;
}

.reciever-class {
  background-color: #404040;
}

.message-box {
  color: white;
  margin-bottom: 0px;
  max-width: 60% !important;
  font-size: 0.75rem;
}

.input-container {
  height: 37px;
  width: 100%;
  padding-right: 40px;
}

.input-box {
  background-color: rgba(128, 128, 128, 0.7);
  color: white;
  font-weight: 400;
  font-size: 15px;
  border: 0;
  resize: none;
  height: 100%;
  scrollbar-color: $scrollbarHaefele;
  scrollbar-width: thin;
  margin-right: 20px;
  margin-left: 20px;
}

.massenger-button {
  width: 125px;
  height: 100%;
  font-size: 17px;
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 0px !important;
  text-transform: none;
  position: relative;
}

.send-button {
  background-color: $secondaryColor;
  margin-right: 20px !important;
}

.emoji-container {
  width: 37px;
}

.send-icon {
  margin-bottom: 2.5px;
}

.video-icon {
  margin-bottom: 2.5px;
}

/* End Chat Css */

.button-content {
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-50%);
}

.right-section {
  padding-left: 40px !important;
}

.upper-section {
  height: 50%;
  padding-bottom: 20px !important;
}

.seperator {
  background-color: rgba(216, 191, 216, 0.5);
  width: calc(100% - 40px);
  min-height: 1px;
}

.lower-section {
  height: calc(50% - 1px);
  padding-top: 20px !important;
}

.mobile-seperator {
  display: none;
}

@media screen and (min-width: 1920px) {
  .exhibition-content {
    padding-right: 100px;
    padding-left: 100px;
  }

  .status-buttons-container {
    padding-right: 79px;
  }

  .right-section {
    padding-left: 79px !important;
  }

  .people-container {
    padding-right: 79px;
  }

  .upper-section {
    height: 40%;
    padding-bottom: 40px !important;
  }

  .lower-section {
    height: calc(60% - 1px);
    padding-top: 40px !important;
  }

  .contact-section {
    padding-right: 31px !important;
  }

  .exhibits-section {
    padding-left: 31px !important;
  }

  .messages {
    padding-bottom: 30px;
  }

  .button-content {
    left: 17px;
  }
}

.busy {
  opacity: 0.5;
  cursor: default;
}
.busy:hover {
  position: relative;
  cursor: not-allowed !important;
  &:after {
    content: "Beschäftigt";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background: white;
  }
}

@media screen and (max-width: 1340px) {
  .note-button {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 760px) {
  input {
    font-size: 8px;
  }

  p,
  div,
  span,
  button,
  textarea {
    font-size: 10px !important;
  }

  h1 {
    font-size: 14px !important;
  }

  .mobile-seperator {
    display: block;
  }

  .exhibition-content {
    padding-bottom: 50px;
    height: 100% !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .people-container {
    max-height: 100vh !important;
    padding-right: 0px;
  }

  .status-buttons-container {
    padding-right: 0px;
    margin-bottom: 30px;
  }

  .contact-section {
    border: 0 !important;
    padding-right: 0px !important;
  }

  .note-buttons-container {
    margin-left: 0px;
  }

  .note-button {
    width: 30% !important;
    margin-right: 0px !important;
    margin-top: 10px;
  }

  .share-button {
    margin-right: 10px !important;
  }

  .save-button {
    margin-left: 10px !important;
  }

  .right-section {
    padding-left: 0px !important;
  }

  .exhibits-section {
    padding: 0px !important;
  }

  .search-container {
    padding-right: 0px;
  }

  .product-container {
    padding-right: 0px;
  }

  .messages-container {
    max-height: 200px;
    padding-right: 0px;
  }

  .input-container {
    padding-right: 0px;
  }

  .chat-button-text {
    display: none;
  }

  .massenger-button {
    width: 40px;
  }

  .input-box {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .send-button {
    margin-right: 10px !important;
  }

  .status-button {
    height: 25px;
  }
}
</style>
